.info-slider > .carousel-item {
  height: 250px;
}

.info-slider > .carousel-item > img {
  object-fit: contain;
  background: #f9faff;
}

.color-gray {
  color: #969696;
}
/* TODO: refactor this */
.info-contact > p {
  display: flex;
  align-items: center;
}

.info-contact > p > svg {
  margin-right: 6px;
}

