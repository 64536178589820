/* @import-normalize; */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

.price-color {
  color: #f4792a;
}

#root {
  background: #f9faff;
}

.bottom-tabs {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.icon { 
  width: 24px; 
  height: 24px;
}

.discount {
  /* position: relative; */
  color: #969696;
  display: block;
}
.discount::after {
  content: '';
  background: #ccc;
  position: absolute;
  width: 32px;
  height: 2px;
  left: 12px;
  top: 8px;
  transform: rotate(160deg);
  bottom: 7px;
}

.Toastify__toast-container {
  z-index: 10000000;
}

.statusbar {
  background-color: #f4792a;
}
@media screen and (orientation:portrait) {
  .statusbar { height: 3.90625vh; }
}
@media screen and (orientation:landscape) {
  .statusbar { height: 6.94444vh; }
}

input, .input {
  font-size: 16px;
}